import { extendTheme } from '@chakra-ui/react'
import '@fontsource/open-sans'
import '@fontsource/raleway'

const theme = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
    styles: {
        global: (props) => ({
            body: {
                color: 'default',
                bg: 'linear-gradient(to right top, #051937, #004066, #006c8a, #009999, #00c595)',
                bgSize: 'cover',
                height: '100vh',
                width: '100%',
            },
        }),
    },
    fonts: {
        heading: `'Open Sans', sans-serif`,
    },

    colors: {
        default: 'white',
        brand: {
            50: 'yellow',
            100: 'green',
            500: 'blue', // you need this
        },
    },
}

export default extendTheme(theme)
